<script lang="ts">
import data from '@/assets/data.json'
import { Protocol } from '@/types/protocolType'

function toProtocol(item:any): Protocol {
  return {
    name: item.protocol,
    wln: item.wln,
    flespi: item.flespi,
    pro: item.pro,
    gis: item.gis,
    flespiRowspan: item.flespiRowspan,
    gisRowspan: item.gisRowspan,
    class: item.class
  }
}
export default {
  data() {
    return {
      data: data.protocols.map((item) => (toProtocol(item))) as Protocol[]
    }
  }
}

</script>

<template>
  <h4>Диапазон портов для подключения:</h4>
  <table class="protocols">
    <tr>
      <th>Тип устройства</th>
      <th>Виалон 165.165</th>
      <th>Флеспи 165.37</th>
      <th>ГИС 82.151.126.4</th>
      <th>PRO 82.151.126.4</th>
    </tr>
    <tr v-for="item in data">
      <td>{{ item.name }}</td>
      <td class="cloudBackground">{{ item.wln }}</td>
      <td class="cloudBackground" :rowspan="item.flespiRowspan" v-if="item.flespi">{{ item.flespi }}</td>
      <td class="cloudBackground" :rowspan="item.gisRowspan" v-if="item.gis">{{ item.gis }}</td>
      <td class="rtBackground">{{ item.pro }}</td>
    </tr>
  </table>
  <div>,где <div class="cloudBackground legend"></div> - порты, направленные на облачный роутер</div>
  <div>,а <div class="rtBackground legend"></div> - порты, направленные напрямую на Ростелеком</div>
</template>

<style scoped>
td, th{
  border: 1px black solid;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: normal;
  padding-top: 3px;
  text-align: left;
}
th{
  font-weight: bold;
}
.protocols{
  border-spacing: 0;
  border-collapse: collapse;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.protocols th{
  background-color: #E6E6E6;
}
.rtBackground{
  background-color: rgba(120, 0, 255, 0.25);
}
.cloudBackground{
  background-color: rgba(136, 221, 76, 0.64);
}
.legend{
  width: 10px;
  height: 10px;
  border: 1px solid black;
  display: inline-block;
}
</style>